<template>
  <NuxtLink
      v-if="showComponent"
      :key="fullname + weapon"
      :to="getWeaponLink(weapon || '')"
      class="category-link f"
  >
    <img :alt="(fullname || '') + ' category item'"
         :src="getImageFromWaxpeer( fullname || '', '120:120')"
         class="link-icon"
         height="50"
         loading="lazy"
         width="50"
    />
    <span class="link-name">{{ $t('filters.weapon.' + weapon) }}</span>
    <span class="link-border"></span>
  </NuxtLink>
</template>

<script lang="ts" setup>
const props = defineProps<{ fullname: string, weapon: string }>()

const {showComponent} = await useDelayShow()
</script>

<style lang="scss" scoped>
@use '/assets/scss/variables.scss' as *;

.category-link {
  height: 100%;
  width: 100%;
  border-bottom: 1px solid $darkLight;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 5rem;
    height: 5rem;
    background: radial-gradient(50% 50% at 50% 50%, rgba($green, 0.25) 0%, rgba($green, 0) 100%);
    top: 0;
    left: 0;
    z-index: 0;
  }

  .link-icon {
    position: relative;
    z-index: 1;
    width: 5rem;
    height: 5rem;
    padding: 0.5rem;
    object-fit: contain;
    object-position: center;
  }

  .link-name {
    font-weight: 400;
    font-size: 1.4rem;
    color: $light;
    margin-left: 1.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    text-align: left;
    line-height: 5rem;
  }

  @media screen and (max-width: 1115px) {
    .link-icon, &:after {
      width: 4rem;
      height: 4rem;
      padding: 0;
    }

    .link-name {
      line-height: 4.3rem;
    }
  }
}
</style>
